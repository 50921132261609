import MarkdownToJsx from 'markdown-to-jsx'
import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'
import HelpIcon from '@thebeansgroup/ui_icons/help_icon'

import { getCopy } from './helpers'
import * as styles from './styles'

const HelpCenterLink = ({ t, country }) => {
  return (
    <div css={styles.container}>
      <HelpIcon />
      <MarkdownToJsx
        options={{
          overrides: {
            a: {
              props: {
                target: '_blank',
                style: {
                  color: 'inherit',
                  textDecoration: 'underline'
                }
              }
            }
          }
        }}
      >
        {getCopy({ t, country })}
      </MarkdownToJsx>
    </div>
  )
}

HelpCenterLink.propTypes = {
  t: PropTypes.func.isRequired,
  country: PropTypes.string.isRequired
}

export default withI18n(HelpCenterLink)
