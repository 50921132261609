import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import React from 'react'

import ChevronIcon from '@thebeansgroup/sb-ui/icons/chevron_icon'
import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { Callout, Footnote } from '@thebeansgroup/sb-ui/typography'

import { SUBTITLE_OPACITY } from './constants'
import { getImageWidthAndHeight } from './helpers'
import styles from './styles'

const PageButton = ({
  icon: Icon,
  brandImage,
  title,
  onClick,
  description,
  brandColour,
  isOfferButton
}) => {
  const renderImage = () => {
    if (!Icon && !brandImage) return null

    const widthHeight = getImageWidthAndHeight(Icon)

    if (Icon) {
      return (
        <div className={css(styles(brandColour).imageHolder)}>
          <Icon
            colour={brandColour}
            width={widthHeight}
            height={widthHeight}
          />
        </div>
      )
    }

    return (
      <div className={css(styles(brandColour).imageHolder)}>
        <img
          src={brandImage}
          width={widthHeight}
          height={widthHeight}
        />
      </div>
    )
  }

  const renderDescription = () => {
    if (!description) return null

    return (
      <Footnote
        colour={`${brandColour}${SUBTITLE_OPACITY}`}
        className={css(styles(brandColour).buttonDescription)}
      >
        {description}
      </Footnote>
    )
  }
  const renderButtonText = () => {
    const variant = isOfferButton ? 'semibold' : ''
    return (
      <span className={css(styles(brandColour).buttonTextHolder)}>
        <Callout
          variant={variant}
          colour={brandColour}
        >
          {title}
        </Callout>
        {renderDescription()}
      </span>
    )
  }

  return (
    <div className={css(styles(brandColour).wrapper)}>
      <button
        data-testid='page-button'
        onClick={onClick}
        className={css(styles(brandColour).buttonBase)}
      >
        {renderImage()}
        {renderButtonText()}
        <div className={css(styles(brandColour).chevronHolder)}>
          <ChevronIcon
            style={{ marginLeft: 'auto', fontSize: '24px' }}
            colour={`${brandColour}${SUBTITLE_OPACITY}`}
          />
        </div>
      </button>
    </div>
  )
}

PageButton.defaultProps = {
  icon: '',
  description: '',
  brandColour: Colours('grey', 500),
  isOfferButton: false
}

PageButton.proptypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  description: PropTypes.string,
  brandColour: PropTypes.string,
  isOfferButton: PropTypes.bool
}

export default PageButton
