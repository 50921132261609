/**
 * @generated SignedSource<<ebf612ffd31e0573885f968e7a3063b8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "tileTemplateOffer_connectOfferPage",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "authButton_connectOfferPage"
    }
  ],
  "type": "ConnectOfferPage",
  "abstractKey": null
};

node.hash = "debfd6798400113afba310c076bb3dad";

module.exports = node;
