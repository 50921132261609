/**
 * @generated SignedSource<<81f0c0f28d377bc89bdaa7178f370ca7>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "multiOffer_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "multiOfferTitle_brand"
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

node.hash = "c5472849781b45072e588248c3678537";

module.exports = node;
