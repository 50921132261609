import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import ThirdPartyTracking from '@thebeansgroup/third_party_tracking'

import MultiConnectDescription from '@src/components/multi_connect_description'
import { dedupeOffers } from '@src/components/template/helpers'
import { shouldShowMultiOfferPage } from '@src/helpers'

import MultiOfferButton from './children/multi_offer_button'
import MultiOfferTitle from './children/multi_offer_title'
import styles from './styles'

const MultiCCG = ({
  brand,
  connectOfferPage,
  addOfferUidToQueryString,
  activeOfferIds,
  templateConfig,
  selectCCG,
  isExpress
}) => {
  const { multiOffer } = connectOfferPage
  const dedupedOffers = dedupeOffers(connectOfferPage.offers)

  useEffect(() => {
    ThirdPartyTracking.sessionReplay('?page=multi_ccg')
  }, [])

  const handleButtonClick = (offer) => {
    if (!shouldShowMultiOfferPage(multiOffer)) {
      return addOfferUidToQueryString(offer.uid)
    }

    return selectCCG(offer.consumerGroup, dedupedOffers)
  }

  const renderCCGButtons = () =>
    dedupedOffers.map((offer) => {
      if (!offer?.uid || !activeOfferIds.includes(offer.uid)) return null

      return (
        <MultiOfferButton
          key={offer.uid}
          offer={offer}
          onClick={() => handleButtonClick(offer)}
          {...templateConfig?.button}
          brandColour={templateConfig?.subtitle?.textColour}
        />
      )
    })

  return (
    <>
      <MultiOfferTitle brand={brand} />
      <MultiConnectDescription
        offers={dedupedOffers}
        textColour={templateConfig?.subtitle?.textColour}
      />
      <div className={css(styles.wrapper)}>{renderCCGButtons()}</div>
    </>
  )
}

MultiCCG.propTypes = {
  brand: PropTypes.object.isRequired,
  connectOfferPage: PropTypes.shape({
    offers: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  addOfferUidToQueryString: PropTypes.func.isRequired,
  activeOfferIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  templateConfig: PropTypes.object.isRequired,
  isExpress: PropTypes.bool.isRequired
}

export default createFragmentContainer(MultiCCG, {
  connectOfferPage: graphql`
    fragment multiCcg_connectOfferPage on ConnectOfferPage {
      multiOffer
      offers {
        uid
        consumerGroup
        status
        enabled
      }
    }
  `,
  brand: graphql`
    fragment multiCcg_brand on Brand {
      ...multiOfferTitle_brand
    }
  `
})
