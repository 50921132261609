/**
 * @generated SignedSource<<d274be9596e59a395169443f0cc19c8e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "tileTemplateOffer_offer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "tileTemplateTitle_offer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "tileTemplateDescription_offer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "tileTemplateSubtitle_offer"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boosted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boostedExtraText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "boostedWasText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expired",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiringSoon",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "expiryMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "discountEndDate",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "consumerGroup",
      "storageKey": null
    }
  ],
  "type": "Offer",
  "abstractKey": null
};

node.hash = "2b1a21d9a8bac9721d2cc7844eb356b4";

module.exports = node;
