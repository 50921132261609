/**
 * @generated SignedSource<<4c9e5815d01ebac611984af34fcff123>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "multiCcg_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "multiOfferTitle_brand"
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

node.hash = "fc8680cb80880f02e2eb6dadc3d4772c";

module.exports = node;
