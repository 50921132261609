import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const container = css`
  background: ${Colours('grey', 100)};
  display: flex;
  padding: 4px;
  width: 90%;
  justify-content: center;
  align-items: center;
  border-radius: 4px 4px 0 0;
  z-index: 1;
  gap: 4px;
  max-width: 380px;
`

export const icon = css`
  width: 16px;
  height: 16px;
  margin-top: -2px;
`
