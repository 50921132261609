import { withRouter } from 'found'
import PropTypes from 'prop-types'
import React, { Fragment, useState, useRef } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import OfferTemplate from '@src/components/offer_template'
import { useIsExpressConnect } from '@src/hooks/use_is_express_connect'
import {
  addMultipleQueryStringValues,
  addQueryStringValue
} from '@src/routing/helpers'
import MultiCCG from '@src/templates/standard/children/multi_ccg'
import MultiOffer from '@src/templates/standard/children/multi_offer'
import StandardWrapper from '@src/templates/standard/children/standard_wrapper'

import { getTemplateConfig } from '@helpers/index'

import {
  getActiveOfferIds,
  getBeansBrand,
  getOfferFromOffersById,
  shouldRenderNewCodeRevealTemplate,
  shouldShowMultiConnect,
  shouldShowMultiOfferView
} from './helpers'

const Template = ({
  country,
  viewer,
  onLogout,
  connectOfferPage,
  slug,
  router,
  match,
  selectedOfferId,
  selectedCCG
}) => {
  const isMultiOffer = useRef(
    shouldShowMultiOfferView(
      selectedCCG,
      selectedOfferId,
      connectOfferPage.multiOffer
    )
  )
  const isExpress = useIsExpressConnect(match)
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [page, setPage] = useState('')
  const shouldDisplayNewTemplate = shouldRenderNewCodeRevealTemplate(
    page,
    connectOfferPage.offer
  )

  const isMultiCCG = useRef(
    shouldShowMultiConnect(selectedCCG, selectedOfferId)
  )
  const [templateConfig] = useState(getTemplateConfig(connectOfferPage))

  const addOfferUidToQueryString = (offerUid) => {
    const queryStringObj = {
      name: 'offer_uid',
      value: offerUid
    }

    addQueryStringValue(router, match, queryStringObj)
  }

  const addCCGToQueryString = (consumerGroup, dedupedOffers) => {
    const queryStringArr = [
      {
        name: 'consumer_group',
        value: consumerGroup
      }
    ]

    const offersForCCG = dedupedOffers.filter(
      (offer) => offer.consumerGroup === consumerGroup
    )

    if (offersForCCG.length === 1) {
      queryStringArr.push({
        name: 'offer_uid',
        value: offersForCCG[0].uid
      })
    }

    addMultipleQueryStringValues(router, match, queryStringArr)
  }

  const renderMultiCCGConnect = () => {
    if (!shouldShowMultiConnect(selectedCCG, selectedOfferId)) return null

    const activeOfferIds = getActiveOfferIds(connectOfferPage.offers)
    const beansBrand = getBeansBrand(connectOfferPage.offers)

    return (
      <StandardWrapper
        viewer={viewer}
        brand={connectOfferPage?.brand}
        offer={connectOfferPage.offer}
        onLogout={onLogout}
        templateConfig={templateConfig}
        country={country}
        beansBrand={beansBrand}
      >
        <MultiCCG
          brand={connectOfferPage?.brand}
          connectOfferPage={connectOfferPage}
          addOfferUidToQueryString={addOfferUidToQueryString}
          templateConfig={templateConfig}
          activeOfferIds={activeOfferIds}
          selectCCG={addCCGToQueryString}
          isExpress={isExpress}
        />
      </StandardWrapper>
    )
  }

  const renderMultiOffer = () => {
    const { multiOffer } = connectOfferPage
    if (!shouldShowMultiOfferView(selectedCCG, selectedOfferId, multiOffer)) {
      return null
    }

    return (
      <StandardWrapper
        viewer={viewer}
        brand={connectOfferPage?.brand}
        offer={connectOfferPage.offer}
        onLogout={onLogout}
        templateConfig={templateConfig}
        country={country}
      >
        <MultiOffer
          brand={connectOfferPage?.brand}
          connectOfferPage={connectOfferPage}
          addOfferUidToQueryString={addOfferUidToQueryString}
          templateConfig={templateConfig}
          selectedCCG={selectedCCG}
          isExpress={isExpress}
        />
      </StandardWrapper>
    )
  }

  const renderOffer = () => {
    if (!selectedOfferId) return null

    const offer = getOfferFromOffersById(
      selectedOfferId,
      connectOfferPage.offers
    )

    return (
      <OfferTemplate
        isExpress={isExpress}
        offer={offer}
        viewer={viewer}
        connectOfferPage={connectOfferPage}
        onLogout={onLogout}
        isLoggedIn={isLoggedIn}
        templateConfig={templateConfig}
        shouldDisplayNewTemplate={shouldDisplayNewTemplate}
        country={country}
        slug={slug}
        page={page}
        isMultiCCG={isMultiCCG.current}
        isMultiOffer={isMultiOffer.current}
        setIsLoggedIn={setIsLoggedIn}
        match={match}
        setPage={setPage}
      />
    )
  }
  return (
    <Fragment>
      {renderMultiCCGConnect()}
      {renderMultiOffer()}
      {renderOffer()}
    </Fragment>
  )
}

Template.defaultProps = {
  selectedOfferId: null
}

Template.propTypes = {
  country: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  onLogout: PropTypes.func.isRequired,
  viewer: PropTypes.object.isRequired,
  connectOfferPage: PropTypes.object.isRequired,
  selectedOfferId: PropTypes.string
}

export default createFragmentContainer(withRouter(Template), {
  viewer: graphql`
    fragment template_viewer on AccountsViewer {
      ...standardIntro_viewer
      ...standardWrapper_viewer
      ...standardIssuance_viewer
      ...offerDescription_viewer
    }
  `,
  connectOfferPage: graphql`
    fragment template_connectOfferPage on ConnectOfferPage {
      multiOffer
      ...tileTemplateOffer_connectOfferPage
      ...standardIssuance_connectOfferPage
      ...multiCcg_connectOfferPage
      ...multiOffer_connectOfferPage
      offer {
        ...tileTemplateOffer_offer
        ...standardIntro_offer
        ...standardIssuance_offer
        ...standardWrapper_offer
        ...offerTitle_offer
        uid
        redemptionType
      }
      offers {
        ...tileTemplateOffer_offer
        ...standardIntro_offer
        ...standardIssuance_offer
        ...standardWrapper_offer
        ...offerTitle_offer
        ...offerDescription_offer
        enabled
        status
        uid
        consumerGroup
        redemptionType
      }
      brand {
        ...tileTemplateOffer_brand
        ...tileTemplateTitle_brand
        ...standardIntro_brand
        ...standardIssuance_brand
        ...standardWrapper_brand
        ...tileTemplateLayout_brand
        ...multiCcg_brand
        ...multiOffer_brand
        ...offerTitle_brand
        ...offerDescription_brand
      }
      templateConfig
    }
  `
})
