import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const stepContainer = css`
  display: flex;
`

export const stepNumber = css`
  background-color: ${Colours('grey', 500)};
  height: 24px;
  width: 24px;
  border-radius: 50px;
  color: ${Colours('white')};
  text-align: center;
  line-height: 26px;
  font-size: 13px;
`

export const stepLine = css`
  background-color: ${Colours('grey', 500)};
  flex: 1;
  width: 2px;
  height: 8px;
  align-self: center;
  padding: 5px 0;
  border-radius: 4px;
  margin: 3px 0;
`

export const stepNumberContainer = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
`

export const stepContent = css`
  padding-left: 10px;
`

export const list = css`
  padding: 0;
  list-style: none;
`
