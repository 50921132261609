import { css } from '@emotion/react'

import { brotherAndAbove } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const mobileTitle = css`
  display: block;

  ${brotherAndAbove} {
    display: none;
  }
`

export const desktopTitle = css`
  display: none;

  ${brotherAndAbove} {
    display: block;
  }
`
