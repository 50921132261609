import { getBeansBrand } from '@src/components/template/helpers'

import {
  MULTI_CCG_FALLBACKS,
  MULTI_OFFER_FALLBACK,
  MULTI_OFFER_VARIANT
} from './constants'

export const getDescription = (t, offers, variant) => {
  if (variant === MULTI_OFFER_VARIANT)
    return t('m_multi_offer_description') || MULTI_OFFER_FALLBACK

  const beansBrand = getBeansBrand(offers)?.toLowerCase()
  return (
    t(`m_multi_offer_description_${beansBrand}`) ||
    MULTI_CCG_FALLBACKS[beansBrand]
  )
}
