import {
  formatConsumerGroupForTranslations,
  titleCaseConsumerGroup
} from '../../../../../../helpers'

export const getButtonText = (consumerGroup, t) => {
  const formattedConsumerGroup =
    formatConsumerGroupForTranslations(consumerGroup)
  return (
    t(`a_discount_${formattedConsumerGroup}`) ||
    `${titleCaseConsumerGroup(consumerGroup)} Discount`
  )
}
