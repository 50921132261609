import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import BrandImages from '@src/components/brand_images'

import HelpCenterLink from '@components/helper_center_link'
import SocialProofCard from '@components/social_proof_card'
import { getBackgroundColour } from '@helpers/index'
import { useEditorData } from '@hooks/use_editor_context'

import * as styles from './styles'

const TileTemplateLayout = ({
  brand,
  templateConfig,
  children,
  hasSocialProof,
  country
}) => {
  const [backgroundColour, setBackgroundColour] = useState(
    getBackgroundColour(templateConfig)
  )

  const { editorState } = useEditorData()

  useEffect(() => {
    if (editorState) {
      setBackgroundColour(editorState.colourState?.background?.value)
    }
  }, [editorState])

  const renderImages = () => (
    <BrandImages
      brand={brand}
      templateConfig={templateConfig}
    />
  )

  const renderSocialProof = () => {
    if (!hasSocialProof) return null

    return <SocialProofCard />
  }

  const renderContent = () => {
    return (
      <div css={styles.content(hasSocialProof)}>
        {renderSocialProof()}
        <div
          data-testid='card'
          css={styles.card}
        >
          {children}
        </div>

        <div css={styles.helpCenterLink}>
          <HelpCenterLink country={country} />
        </div>
      </div>
    )
  }

  return (
    <div
      data-testid='backgroundBase'
      css={[
        styles.wrapper,
        backgroundColour && styles.customBackground(backgroundColour)
      ]}
    >
      {renderImages()}
      {renderContent()}
    </div>
  )
}

TileTemplateLayout.defaultProps = {
  hasSocialProof: false
}

TileTemplateLayout.propTypes = {
  children: PropTypes.node.isRequired,
  viewer: PropTypes.object.isRequired,
  country: PropTypes.string.isRequired,
  brand: PropTypes.object.isRequired,
  templateConfig: PropTypes.object.isRequired,
  hasSocialProof: PropTypes.bool
}

export default createFragmentContainer(TileTemplateLayout, {
  brand: graphql`
    fragment tileTemplateLayout_brand on Brand {
      ...brandImages_brand
    }
  `
})
