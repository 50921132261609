/**
 * @generated SignedSource<<976726038cd768d93f0586b2b65596c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "multiOfferTitle_brand",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

node.hash = "569be43bc73e9f9fb7fca217f212ebfa";

module.exports = node;
