import { DEFAULT_VISITOR_DATA, PLATFORM } from './constants'

export const generateVisitorData = (country, data) => {
  if (!data || !country) return null

  const sbid = getSbid(data)
  const lowerCaseCountryCode = country?.toLowerCase()

  if (!sbid) return createAnonymousVisitor(lowerCaseCountryCode)

  return createVisitor(lowerCaseCountryCode, sbid)
}

export const createVisitor = (country, sbid) => ({
  id: sbid,
  isAuthenticated: true,
  hasConsented: true,
  context: {
    platform: PLATFORM,
    country
  }
})

export const createAnonymousVisitor = (country) => ({
  ...DEFAULT_VISITOR_DATA,
  context: {
    platform: PLATFORM,
    country
  }
})

export const getSbid = (data) => data.userProfile?.idCode ?? null
