import { getBrandName } from '@helpers/brand'

export const getOfferTitle = ({
  t,
  brand,
  offer,
  editorBrand,
  editorOffer
}) => {
  const title = editorOffer ?? offer?.title
  const translation = `${title} ${t('m_at_brand', {
    brand: editorBrand ?? getBrandName(brand)
  })}`
  return translation
}
