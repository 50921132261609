export const STUDENT_CONSUMER_GROUP = 'student'
export const GRADUATE_CONSUMER_GROUP = 'graduate'
export const HEALTHCARE_CONSUMER_GROUP = 'healthcare'
export const FIRST_RESPONDER_CONSUMER_GROUP = 'first-responder'
export const MILITARY_CONSUMER_GROUP = 'military'
export const TEACHER_CONSUMER_GROUP = 'teacher'
export const DISABILITY_CONSUMER_GROUP = 'disability'
export const PARENT_CONSUMER_GROUP = 'parent'
export const CHARITY_WORKER_CONSUMER_GROUP = 'charity-worker'
export const LOW_INCOME_CONSUMER_GROUP = 'low-income'
