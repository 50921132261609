import PropTypes from 'prop-types'

import { isFinalStep } from './helpers'
import * as styles from './styles'

const VerticalStepper = ({ steps }) => {
  const renderStepLine = (index) => {
    if (isFinalStep(index, steps)) return null

    return <div css={styles.stepLine} />
  }

  const renderSteps = () => {
    return steps.map((item, index) => {
      return (
        <li
          key={index}
          css={styles.stepContainer}
        >
          <div css={styles.stepNumberContainer}>
            <div css={styles.stepNumber}>{index + 1}</div>
            {renderStepLine(index)}
          </div>
          <div css={styles.stepContent}>{item}</div>
        </li>
      )
    })
  }

  return <ol css={styles.list}>{renderSteps()}</ol>
}

VerticalStepper.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.node).isRequired
}

export default VerticalStepper
