import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const container = css`
  display: flex;
  gap: 2px;
  align-items: center;
  color: ${Colours('grey', 500)};
`
