import { StyleSheet } from 'aphrodite'

import { Fonts } from '@thebeansgroup/sb-ui/style_helpers'
import {
  baby,
  sister,
  brother
} from '@thebeansgroup/sb-ui/style_helpers/queries'

export const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    marginTop: '28px',
    marginBottom: '16px',

    ...baby({
      marginTop: '20px',
      marginBottom: '20px'
    })
  },

  mobileTitle: {
    ...brother({
      display: 'none'
    }).andAbove()
  }
})

export const desktopTitle = (textColour) =>
  StyleSheet.create({
    base: {
      fontSize: '37px',
      margin: '0',
      fontFamily: Fonts('gibsonSemiBold'),
      lineHeight: '1.25em',
      color: textColour,
      ...sister({
        display: 'none'
      }).andBelow()
    }
  })
