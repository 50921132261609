import { css } from 'aphrodite'
import PropTypes from 'prop-types'

import { withI18n } from '@thebeansgroup/_i18n'
import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import { Body } from '@thebeansgroup/sb-ui/typography'

import Markdown from '@components/markdown'

import { MULTI_CCG_VARIANT, MULTI_OFFER_VARIANT } from './constants'
import { getDescription } from './helpers'
import styles from './styles'

const MultiConnectDescription = ({ textColour, t, offers, variant }) => (
  <div className={css(styles.wrapper)}>
    <Body
      component='div'
      colour={textColour}
    >
      <Markdown>{getDescription(t, offers, variant)}</Markdown>
    </Body>
  </div>
)

MultiConnectDescription.defaultProps = {
  textColour: Colours('grey', 500),
  variant: MULTI_CCG_VARIANT
}

MultiConnectDescription.propTypes = {
  textColour: PropTypes.string,
  t: PropTypes.func.isRequired,
  offers: PropTypes.array.isRequired,
  variant: PropTypes.oneOf([MULTI_CCG_VARIANT, MULTI_OFFER_VARIANT])
}

export default withI18n(MultiConnectDescription)
