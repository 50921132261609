import CharityWorkerIcon from '@thebeansgroup/ui_icons/charity_worker_icon'
import DisabilityIcon from '@thebeansgroup/ui_icons/disability_icon'
import FirstResponderIcon from '@thebeansgroup/ui_icons/first_responder_icon'
import GraduateIcon from '@thebeansgroup/ui_icons/graduate_icon'
import HealthcareIcon from '@thebeansgroup/ui_icons/healthcare_icon'
import LowIncomeIcon from '@thebeansgroup/ui_icons/low_income_icon'
import MilitaryIcon from '@thebeansgroup/ui_icons/military_icon'
import ParentIcon from '@thebeansgroup/ui_icons/parent_icon'
import StudentIcon from '@thebeansgroup/ui_icons/student_icon'
import TeacherIcon from '@thebeansgroup/ui_icons/teacher_icon'

export const GRADUATE_CONSUMER_GROUP = 'graduate'
export const CCG_ICON_CONFIG = {
  student: StudentIcon,
  graduate: GraduateIcon,
  healthcare: HealthcareIcon,
  'first-responder': FirstResponderIcon,
  military: MilitaryIcon,
  teacher: TeacherIcon,
  disability: DisabilityIcon,
  parent: ParentIcon,
  'charity-worker': CharityWorkerIcon,
  'low-income': LowIncomeIcon
}
