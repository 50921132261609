import { css } from 'aphrodite'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'
import { Colours } from '@thebeansgroup/sb-ui/style_helpers'
import Title2 from '@thebeansgroup/sb-ui/typography/title/title2'

import { getMultiOfferTitle } from './helpers'
import { styles, desktopTitle } from './styles'

const MultiOfferTitle = ({ brand, textColour, t }) => {
  const renderMobileTitle = () => (
    <div className={css(styles.mobileTitle)}>
      <Title2
        component='h1'
        colour={textColour}
      >
        {getMultiOfferTitle(brand, t)}
      </Title2>
    </div>
  )

  const renderDesktopTitle = () => (
    <h1 className={css(desktopTitle(textColour).base)}>
      {getMultiOfferTitle(brand, t)}
    </h1>
  )

  return (
    <div className={css(styles.wrapper)}>
      {renderMobileTitle()}
      {renderDesktopTitle()}
    </div>
  )
}

MultiOfferTitle.defaultProps = {
  offer: null,
  textColour: Colours('grey', 500)
}

MultiOfferTitle.propTypes = {
  textColour: PropTypes.string,
  offer: PropTypes.object,
  brand: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired
}

export default createFragmentContainer(withI18n(MultiOfferTitle), {
  brand: graphql`
    fragment multiOfferTitle_brand on Brand {
      name
    }
  `
})
