import PropTypes from 'prop-types'
import React from 'react'

import TermsLink from '@src/components/terms_link'
import StandardIntro from '@src/templates/standard/children/standard_intro'
import StandardIssuance from '@src/templates/standard/children/standard_issuance'
import StandardWrapper from '@src/templates/standard/children/standard_wrapper'

const StandardOfferTemplate = ({
  offer,
  isExpress,
  viewer,
  connectOfferPage,
  onLogout,
  isLoggedIn,
  templateConfig,
  shouldDisplayNewTemplate,
  country,
  slug,
  page,
  isMultiCCG,
  isMultiOffer,
  setIsLoggedIn,
  match,
  setPage,
  singleWindowIssuance
}) => {
  const renderTermsLink = () => {
    if (shouldDisplayNewTemplate) return null

    return (
      <TermsLink
        slug={slug}
        country={country}
      />
    )
  }
  return (
    <StandardWrapper
      viewer={viewer}
      brand={connectOfferPage?.brand}
      offer={offer}
      onLogout={onLogout}
      templateConfig={templateConfig}
      country={country}
      hideLogo={!isLoggedIn}
      shouldDisplayNewTemplate={shouldDisplayNewTemplate}
    >
      <StandardIntro
        templateConfig={templateConfig}
        viewer={viewer}
        offer={offer}
        brand={connectOfferPage?.brand}
        shouldDisplayNewTemplate={shouldDisplayNewTemplate}
        country={country}
        slug={slug}
        page={page}
      />
      <StandardIssuance
        templateConfig={templateConfig}
        viewer={viewer}
        connectOfferPage={connectOfferPage}
        offer={offer}
        brand={connectOfferPage?.brand}
        country={country}
        isExpress={isExpress}
        shouldIssueInstoreOffersInASingleWindow={singleWindowIssuance}
        shouldIssueOnlineOffersInASingleWindow={singleWindowIssuance}
        isMultiCCG={isMultiCCG.current}
        isMultiOffer={isMultiOffer.current}
        setIsLoggedIn={setIsLoggedIn}
        match={match}
        shouldDisplayNewTemplate={shouldDisplayNewTemplate}
        setPage={setPage}
      />
      {renderTermsLink()}
    </StandardWrapper>
  )
}

StandardOfferTemplate.propTypes = {
  offer: PropTypes.object.isRequired,
  isExpress: PropTypes.bool.isRequired,
  viewer: PropTypes.object.isRequired,
  connectOfferPage: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  templateConfig: PropTypes.object.isRequired,
  shouldDisplayNewTemplate: PropTypes.bool.isRequired,
  country: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  isMultiCCG: PropTypes.bool.isRequired,
  isMultiOffer: PropTypes.bool.isRequired,
  setIsLoggedIn: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  setPage: PropTypes.func.isRequired,
  singleWindowIssuance: PropTypes.bool.isRequired
}

export default StandardOfferTemplate
