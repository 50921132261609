import {
  CHARITY_WORKER_CONSUMER_GROUP,
  DISABILITY_CONSUMER_GROUP,
  FIRST_RESPONDER_CONSUMER_GROUP,
  GRADUATE_CONSUMER_GROUP,
  HEALTHCARE_CONSUMER_GROUP,
  LOW_INCOME_CONSUMER_GROUP,
  MILITARY_CONSUMER_GROUP,
  PARENT_CONSUMER_GROUP,
  TEACHER_CONSUMER_GROUP
} from '@src/constants/consumer_groups'

export const HOW_TO_GET_CODE = 'How to get your code'
export const HOW_TO_GET_DISCOUNT = 'How to get your discount'
const CREATE_BEANSID_ACCOUNT_ITEM = {
  translation: 'm_create_a_free_beansid_account',
  fallback: 'Create a free Beans iD account.'
}
const VERIFY_EMPLOYMENT_ITEM = {
  translation: 'm_verify_employment_status',
  fallback: 'Verify your employment status'
}

const REVEAL_CODE_ITEM = {
  translation: 'm_reveal_enter_code',
  fallback: 'Reveal and enter your code at checkout.'
}

export const STUDENT_CODE_TIMELINE_ITEMS = [
  {
    translation: 'm_create_a_free_sb_account',
    fallback: 'Create a free Student Beans account.'
  },
  {
    translation: 'm_verify_student_status_2mins',
    fallback: 'Verify your student status in 2mins.'
  },
  REVEAL_CODE_ITEM
]

export const STUDENT_NO_CODE_TIMELINE_ITEMS = [
  {
    translation: 'm_create_a_free_sb_account',
    fallback: 'Create a free Student Beans account.'
  },
  {
    translation: 'm_verify_student_status_2mins',
    fallback: 'Verify your student status in 2mins.'
  }
]

export const NON_STUDENT_TIMELINE_ITEMS = {
  [GRADUATE_CONSUMER_GROUP]: [
    {
      translation: 'm_create_a_free_grad_account',
      fallback: 'Create a free Grad Beans account.'
    },
    {
      translation: 'm_verify_graduate_status',
      fallback: 'Verify your graduate status'
    },
    REVEAL_CODE_ITEM
  ],
  [HEALTHCARE_CONSUMER_GROUP]: [
    CREATE_BEANSID_ACCOUNT_ITEM,
    VERIFY_EMPLOYMENT_ITEM,
    REVEAL_CODE_ITEM
  ],
  [MILITARY_CONSUMER_GROUP]: [
    CREATE_BEANSID_ACCOUNT_ITEM,
    {
      translation: 'm_verify_military_employment_status',
      fallback: 'Verify your military employment status'
    },
    REVEAL_CODE_ITEM
  ],
  [TEACHER_CONSUMER_GROUP]: [
    CREATE_BEANSID_ACCOUNT_ITEM,
    VERIFY_EMPLOYMENT_ITEM,
    REVEAL_CODE_ITEM
  ],
  [FIRST_RESPONDER_CONSUMER_GROUP]: [
    CREATE_BEANSID_ACCOUNT_ITEM,
    VERIFY_EMPLOYMENT_ITEM,
    REVEAL_CODE_ITEM
  ],
  [PARENT_CONSUMER_GROUP]: [
    CREATE_BEANSID_ACCOUNT_ITEM,
    {
      translation: 'm_verify_parental_status',
      fallback: 'Verify your parental status'
    },
    REVEAL_CODE_ITEM
  ],
  [LOW_INCOME_CONSUMER_GROUP]: [
    CREATE_BEANSID_ACCOUNT_ITEM,
    {
      translation: 'm_verify_eligibility',
      fallback: 'Verify your eligibility'
    },
    REVEAL_CODE_ITEM
  ],
  [DISABILITY_CONSUMER_GROUP]: [
    CREATE_BEANSID_ACCOUNT_ITEM,
    {
      translation: 'm_verify_eligibility',
      fallback: 'Verify your eligiblity'
    },
    REVEAL_CODE_ITEM
  ],
  [CHARITY_WORKER_CONSUMER_GROUP]: [
    CREATE_BEANSID_ACCOUNT_ITEM,
    VERIFY_EMPLOYMENT_ITEM,
    REVEAL_CODE_ITEM
  ]
}

export const GRADUATION_FOOTNOTE =
  'Enjoy up to 5 years of discounts after graduating!'
