import DiscountIcon from '@images/discount-icon.png'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Subhead from '@thebeansgroup/ui_typography/subhead'

import { DISCOUNT_ALT_TEXT, PLACEHOLDER_TEXT } from './constants'
import * as styles from './styles'

const SocialProofCard = () => {
  const renderIcon = () => (
    <img
      src={DiscountIcon}
      css={styles.icon}
      alt={DISCOUNT_ALT_TEXT}
    />
  )

  const renderText = () => {
    return <Subhead colour={Colours('grey', 400)}>{PLACEHOLDER_TEXT}</Subhead>
  }

  return (
    <div css={styles.container}>
      {renderIcon()}
      {renderText()}
    </div>
  )
}

export default SocialProofCard
