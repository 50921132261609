import { StyleSheet } from 'aphrodite'

import { Colours } from '@thebeansgroup/sb-ui/style_helpers'

import { BORDER_OPACITY, HOVER_OPACITY, SUBTITLE_OPACITY } from './constants'

const styles = (brandColour) =>
  StyleSheet.create({
    wrapper: {
      width: '100%',
      padding: '0 40px'
    },
    buttonBase: {
      padding: '12px',
      bordeRadius: '5px',
      color: brandColour,
      border: `1px solid ${brandColour}${BORDER_OPACITY}`,
      width: '100%',
      display: 'flex',
      margin: '8px 0',
      transition: 'background-color 0.3s',
      alignItems: 'center',
      background: Colours('white'),
      ':hover': {
        backgroundColor: `${brandColour}${HOVER_OPACITY}`
      }
    },
    buttonTextHolder: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'left',
      marginRight: '12px'
    },
    buttonDescription: {
      color: `${brandColour}${SUBTITLE_OPACITY}`
    },
    imageHolder: {
      marginRight: '12px',
      display: 'flex'
    },
    chevronHolder: {
      width: '20px',
      marginLeft: 'auto',
      display: 'flex',
      alignItems: 'center'
    },
    chevron: {
      color: 'black',
      fontSize: '16px'
    }
  })

export default styles
