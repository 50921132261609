import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'
import { brotherAndAbove } from '@thebeansgroup/ui_style_helpers/emotion_media_queries'

export const wrapper = () => css`
  display: flex;
  min-height: 720px;
  flex-direction: column;

  ${brotherAndAbove} {
    flex-direction: row;
  }
`

export const customBackground = (backgroundColor) => css`
  background-color: ${backgroundColor};
`

export const content = (socialProof) => css`
  margin-top: ${socialProof ? '-56px' : '-48px'};
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 1 50%;
  width: 100%;
  padding: 16px;

  ${brotherAndAbove} {
    justify-content: center;
  }
`

export const card = css`
  border: 1px solid ${Colours('grey', 100)};
  z-index: 1;
  padding: 16px;
  display: flex;
  flex-direction: column;
  max-width: 400px;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 40px 60px 0px rgba(23, 27, 30, 0.15);
  background: ${Colours('white')};

  ${brotherAndAbove} {
    margin-top: 20px;
    padding: 32px 32px 24px;
  }
`

export const helpCenterLink = css`
  margin-top: 16px;

  ${brotherAndAbove} {
    margin-top: 45px;
  }
`
