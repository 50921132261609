import { STUDENT_CONSUMER_GROUP } from '@src/constants/consumer_groups'
import { isConsumerGroupGraduate, isConsumerGroupStudent } from '@src/helpers'
import { offerIsNoCode } from '@src/helpers/issuance'

import {
  HOW_TO_GET_CODE,
  HOW_TO_GET_DISCOUNT,
  NON_STUDENT_TIMELINE_ITEMS,
  STUDENT_CODE_TIMELINE_ITEMS,
  STUDENT_NO_CODE_TIMELINE_ITEMS
} from './constants'

export const getTimelineItems = ({
  redemptionType,
  consumerGroup = STUDENT_CONSUMER_GROUP
}) => {
  if (offerIsNoCode({ redemptionType })) return STUDENT_NO_CODE_TIMELINE_ITEMS

  if (!isConsumerGroupStudent(consumerGroup))
    return getNonStudentTimelineItems(consumerGroup)

  return STUDENT_CODE_TIMELINE_ITEMS
}

export const getNonStudentTimelineItems = (consumerGroup) => {
  return NON_STUDENT_TIMELINE_ITEMS[consumerGroup]
}

export const getDescriptionHeading = ({ t, offer }) => {
  if (offerIsNoCode(offer)) {
    return t('d_how_to_get_discount') || HOW_TO_GET_DISCOUNT
  }

  return t('d_how_to_get_code') || HOW_TO_GET_CODE
}

export const getItemText = ({ t, item }) => {
  return t(item.translation) || item.fallback
}

export const shouldDisplayGraduateSubtitle = (consumerGroup, index) => {
  return isConsumerGroupGraduate(consumerGroup) && index === 0
}
