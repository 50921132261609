import PropTypes from 'prop-types'
import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { OFFER_PAGE } from '@src/constants/impression_tracking'

import AuthButton from '@components/auth_button'
import BoostedDetails from '@components/boosted_details'
import PoweredBy from '@components/powered_by'
import Separator from '@components/separator'
import TermsLink from '@components/terms_link'
import TileTemplateDescription from '@components/tile_template_description'
import TileTemplateLayout from '@components/tile_template_layout'
import TileTemplateSubtitle from '@components/tile_template_subtitle'
import TileTemplateTitle from '@components/tile_template_title'

import * as styles from './styles'

const TileTemplateOffer = ({
  viewer,
  offer,
  brand,
  connectOfferPage,
  templateConfig,
  slug,
  country,
  isMultiCCG,
  isMultiOffer,
  isExpress
}) => {
  const renderBoosted = () => {
    return (
      <BoostedDetails
        offer={offer}
        page={OFFER_PAGE}
        variant
      />
    )
  }

  const renderDescription = () => {
    return <TileTemplateDescription offer={offer} />
  }

  const renderTitle = () => {
    return (
      <TileTemplateTitle
        templateConfig={templateConfig}
        offer={offer}
        brand={brand}
      />
    )
  }

  const renderSubtitle = () => {
    return <TileTemplateSubtitle offer={offer} />
  }

  const renderTermsLink = () => {
    return (
      <TermsLink
        slug={slug}
        country={country}
        variant
      />
    )
  }

  const renderSeparator = () => {
    return <Separator />
  }

  const renderAuthButton = () => {
    return (
      <div css={styles.authButton}>
        <AuthButton
          connectOfferPage={connectOfferPage}
          {...templateConfig?.button}
          consumerGroup={offer.consumerGroup}
          isMultiCCG={isMultiCCG}
          isMultiOffer={isMultiOffer}
          country={country}
          isExpress={isExpress}
          hasRegistrationTime
          variant
        />
      </div>
    )
  }

  const renderPoweredBy = () => {
    return (
      <div css={styles.poweredBy}>
        <PoweredBy consumerGroup={offer.consumerGroup} />
      </div>
    )
  }

  return (
    <TileTemplateLayout
      viewer={viewer}
      brand={brand}
      templateConfig={templateConfig}
      country={country}
    >
      <div css={styles.introContainer}>
        {renderBoosted()}
        {renderTitle()}
        {renderSubtitle()}
        {renderTermsLink()}
      </div>
      {renderSeparator()}
      {renderDescription()}
      {renderAuthButton()}
      {renderPoweredBy()}
    </TileTemplateLayout>
  )
}

TileTemplateOffer.propTypes = {
  viewer: PropTypes.object.isRequired,
  brand: PropTypes.object.isRequired,
  connectOfferPage: PropTypes.object.isRequired,
  offer: PropTypes.object.isRequired,
  templateConfig: PropTypes.object.isRequired,
  slug: PropTypes.string.isRequired,
  country: PropTypes.string.isRequired,
  isMultiCCG: PropTypes.bool.isRequired,
  isMultiOffer: PropTypes.bool.isRequired,
  isExpress: PropTypes.bool.isRequired
}

export default createFragmentContainer(TileTemplateOffer, {
  offer: graphql`
    fragment tileTemplateOffer_offer on Offer {
      ...tileTemplateTitle_offer
      ...tileTemplateDescription_offer
      ...tileTemplateSubtitle_offer
      boosted
      boostedExtraText
      boostedWasText
      expired
      expiringSoon
      expiryMessage
      discountEndDate
      consumerGroup
    }
  `,
  brand: graphql`
    fragment tileTemplateOffer_brand on Brand {
      ...tileTemplateTitle_brand
      ...tileTemplateLayout_brand
    }
  `,
  connectOfferPage: graphql`
    fragment tileTemplateOffer_connectOfferPage on ConnectOfferPage {
      ...authButton_connectOfferPage
    }
  `
})
