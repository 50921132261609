import { FlagshipProvider as FSProvider } from '@flagship.io/react-sdk'
import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import { generateVisitorData } from './helpers'

const FlagshipProvider = ({ children, country, viewer }) => {
  const visitorData = generateVisitorData(country, viewer)

  return (
    <FSProvider
      envId={process.env.FLAGSHIP_ENV}
      apiKey={process.env.FLAGSHIP_KEY}
      visitorData={visitorData}
      enableClientCache={process.env.FLAGSHIP_CACHE_ENABLED}
    >
      {children}
    </FSProvider>
  )
}

FlagshipProvider.defaultProps = {
  viewer: null
}

FlagshipProvider.propTypes = {
  country: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  viewer: PropTypes.object
}

export default createFragmentContainer(FlagshipProvider, {
  viewer: graphql`
    fragment flagshipProvider_viewer on AccountsViewer {
      userProfile {
        idCode
      }
    }
  `
})
