import PropTypes from 'prop-types'
import { Fragment, useEffect, useState } from 'react'
import { createFragmentContainer } from 'react-relay'
import { graphql } from 'relay-runtime'

import { withI18n } from '@thebeansgroup/_i18n'
import Title1 from '@thebeansgroup/ui_typography/title1'

import { useEditorData } from '@hooks/use_editor_context'

import { getOfferTitle } from './helpers'
import * as styles from './styles'

const TileTemplateTitle = ({ t, offer, brand, templateConfig }) => {
  const [titleColour, setTitleColour] = useState(
    templateConfig?.title?.textColour
  )
  const [editorBrand, setEditorBrand] = useState(brand.name)
  const [editorOffer, setEditorOffer] = useState(offer.title)
  const { editorState } = useEditorData()

  useEffect(() => {
    if (editorState) {
      setTitleColour(editorState.colourState?.title?.value)
      setEditorBrand(editorState.textState?.brandName?.value)
      setEditorOffer(editorState.textState?.discount?.value)
    }
  }, [editorState])

  return (
    <Fragment>
      <div css={styles.mobileTitle}>
        <Title1
          component='h1'
          colour={titleColour}
          isMobile
        >
          {getOfferTitle({
            t,
            brand,
            offer,
            editorBrand,
            editorOffer
          })}
        </Title1>
      </div>

      <div css={styles.desktopTitle}>
        <Title1
          component='h1'
          colour={titleColour}
        >
          {getOfferTitle({
            t,
            brand,
            offer,
            editorBrand,
            editorOffer
          })}
        </Title1>
      </div>
    </Fragment>
  )
}

TileTemplateTitle.propTypes = {
  t: PropTypes.func.isRequired,
  brand: PropTypes.shape({
    name: PropTypes.string
  }).isRequired,
  offer: PropTypes.shape({
    title: PropTypes.string
  }).isRequired,
  templateConfig: PropTypes.shape({
    title: PropTypes.shape({
      textColour: PropTypes.string
    })
  }).isRequired
}

export default createFragmentContainer(withI18n(TileTemplateTitle), {
  offer: graphql`
    fragment tileTemplateTitle_offer on Offer {
      title
    }
  `,
  brand: graphql`
    fragment tileTemplateTitle_brand on Brand {
      name
    }
  `
})
