/**
 * @generated SignedSource<<5e5b30dcc61a3d23b820d6d0322595d2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "flagshipProvider_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "userProfile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "idCode",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AccountsViewer",
  "abstractKey": null
};

node.hash = "67761b8c0d6a06aa19ea94d930ab31d1";

module.exports = node;
