/**
 * @generated SignedSource<<418221b57d7843879de704e9a62e1d53>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "tileTemplateOffer_offer"
},
v1 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "standardIntro_offer"
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "standardIssuance_offer"
},
v3 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "standardWrapper_offer"
},
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "offerTitle_offer"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uid",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "redemptionType",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "template_connectOfferPage",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "multiOffer",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "tileTemplateOffer_connectOfferPage"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "standardIssuance_connectOfferPage"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "multiCcg_connectOfferPage"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "multiOffer_connectOfferPage"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offer",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        (v5/*: any*/),
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Offer",
      "kind": "LinkedField",
      "name": "offers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        (v2/*: any*/),
        (v3/*: any*/),
        (v4/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "offerDescription_offer"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "consumerGroup",
          "storageKey": null
        },
        (v6/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Brand",
      "kind": "LinkedField",
      "name": "brand",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "tileTemplateOffer_brand"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "tileTemplateTitle_brand"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "standardIntro_brand"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "standardIssuance_brand"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "standardWrapper_brand"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "tileTemplateLayout_brand"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "multiCcg_brand"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "multiOffer_brand"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "offerTitle_brand"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "offerDescription_brand"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "templateConfig",
      "storageKey": null
    }
  ],
  "type": "ConnectOfferPage",
  "abstractKey": null
};
})();

node.hash = "ddf150d758c8d78ab57f119d220a848f";

module.exports = node;
