import { css } from '@emotion/react'

export const authButton = css`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`

export const poweredBy = css`
  margin-top: 24px;
`

export const introContainer = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
