/**
 * @generated SignedSource<<a770eed3b37deb3fee2d06dce3b86169>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "template_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "standardIntro_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "standardWrapper_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "standardIssuance_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "offerDescription_viewer"
    }
  ],
  "type": "AccountsViewer",
  "abstractKey": null
};

node.hash = "37923ff6573eb30e1f7919538e582092";

module.exports = node;
