import PropTypes from 'prop-types'
import React from 'react'

import { shouldIssueOffersInASingleWindow } from '@src/components/template/helpers'
import TileTemplateOffer from '@src/components/tile_template_offer'
import StandardOfferTemplate from '@src/templates/standard/children/offer'

import { shouldRenderTileTemplate } from './helpers'

const OfferTemplate = ({
  isExpress,
  offer,
  viewer,
  connectOfferPage,
  onLogout,
  isLoggedIn,
  templateConfig,
  shouldDisplayNewTemplate,
  country,
  slug,
  page,
  isMultiCCG,
  isMultiOffer,
  setIsLoggedIn,
  match,
  setPage
}) => {
  const singleWindowIssuance =
    shouldIssueOffersInASingleWindow(offer) || isExpress

  if (shouldRenderTileTemplate(page)) {
    return (
      <TileTemplateOffer
        offer={offer}
        viewer={viewer}
        brand={connectOfferPage?.brand}
        connectOfferPage={connectOfferPage}
        templateConfig={templateConfig}
        country={country}
        slug={slug}
        isMultiCCG={isMultiCCG}
        isMultiOffer={isMultiOffer}
        isExpress={isExpress}
      />
    )
  }

  return (
    <StandardOfferTemplate
      offer={offer}
      singleWindowIssuance={singleWindowIssuance}
      isExpress={isExpress}
      viewer={viewer}
      connectOfferPage={connectOfferPage}
      onLogout={onLogout}
      isLoggedIn={isLoggedIn}
      templateConfig={templateConfig}
      shouldDisplayNewTemplate={shouldDisplayNewTemplate}
      country={country}
      slug={slug}
      page={page}
      isMultiCCG={isMultiCCG}
      isMultiOffer={isMultiOffer}
      setIsLoggedIn={setIsLoggedIn}
      match={match}
      setPage={setPage}
    />
  )
}

OfferTemplate.propTypes = {
  isExpress: PropTypes.bool.isRequired,
  offer: PropTypes.object.isRequired,
  viewer: PropTypes.object.isRequired,
  connectOfferPage: PropTypes.object.isRequired,
  onLogout: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired,
  templateConfig: PropTypes.object.isRequired,
  shouldDisplayNewTemplate: PropTypes.bool.isRequired,
  country: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  page: PropTypes.string.isRequired,
  isMultiCCG: PropTypes.bool.isRequired,
  isMultiOffer: PropTypes.bool.isRequired,
  setIsLoggedIn: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  setPage: PropTypes.func.isRequired
}

export default OfferTemplate
