import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'
import Colours from '@thebeansgroup/ui_style_helpers/colours'
import Callout from '@thebeansgroup/ui_typography/callout'

const TileTemplateSubtitle = ({ offer }) => {
  return (
    <Callout
      component='h2'
      colour={Colours('grey', 400)}
    >
      {offer.subtitle}
    </Callout>
  )
}

TileTemplateSubtitle.propTypes = {
  offer: PropTypes.shape({
    subtitle: PropTypes.string
  }).isRequired
}

export default createFragmentContainer(withI18n(TileTemplateSubtitle), {
  offer: graphql`
    fragment tileTemplateSubtitle_offer on Offer {
      subtitle
    }
  `
})
