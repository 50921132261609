export const MULTI_CCG_FALLBACKS = {
  studentbeans:
    "We've teamed up with Student Beans to offer exclusive discounts to the following groups. Click through to verify and receive your discount for free!",
  gradbeans:
    "We've teamed up with Student Beans to offer exclusive discounts to the following groups. Click through to verify and receive your discount for free!",
  beansid:
    "We've teamed up with Student Beans and Beans iD to offer exclusive discounts to the following groups. Click through to verify and receive your discount for free!"
}

export const MULTI_OFFER_FALLBACK =
  'There are multiple discounts available for you to choose from - click through to verify and claim your discounts for free!'

export const MULTI_OFFER_VARIANT = 'multiOffer'
export const MULTI_CCG_VARIANT = 'multiCCG'
