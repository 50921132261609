import { StyleSheet } from 'aphrodite'

import { baby } from '@thebeansgroup/sb-ui/style_helpers/queries'

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '16px',
    marginBottom: '24px',

    ...baby({
      marginTop: '20px',
      marginBottom: '20px'
    })
  }
})

export default styles
