import PropTypes from 'prop-types'
import React from 'react'

import { withI18n } from '@thebeansgroup/_i18n'
import { Colours } from '@thebeansgroup/sb-ui/style_helpers'

import PageButton from '@components/page_button'
import { brandColourDefaultProp } from '@helpers/index'

import { CCG_ICON_CONFIG } from './constants'
import { getButtonText } from './helpers'

const MultiOfferButton = ({ offer, onClick, t, brandColour }) => {
  const renderPageButton = () => {
    return (
      <PageButton
        onClick={onClick}
        title={getButtonText(offer.consumerGroup, t)}
        icon={CCG_ICON_CONFIG[offer.consumerGroup?.toLowerCase()]}
        brandColour={brandColour}
      />
    )
  }

  return <>{renderPageButton()}</>
}

MultiOfferButton.propTypes = {
  buttonColour: PropTypes.string,
  buttonTextColour: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
}

MultiOfferButton.defaultProps = {
  buttonColour: brandColourDefaultProp(),
  buttonTextColour: Colours('white', 300)
}

export default withI18n(MultiOfferButton)
