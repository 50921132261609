import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import ThirdPartyTracking from '@thebeansgroup/third_party_tracking'

import MultiConnectDescription from '@src/components/multi_connect_description'
import { MULTI_OFFER_VARIANT } from '@src/components/multi_connect_description/constants'
import PageButton from '@src/components/page_button'
import { getActiveOffers, dedupeOffers } from '@src/components/template/helpers'

import MultiOfferTitle from '../multi_ccg/children/multi_offer_title'

const MultiOffer = ({
  brand,
  connectOfferPage,
  addOfferUidToQueryString,
  selectedCCG,
  templateConfig,
  isExpress
}) => {
  const activeOffers = getActiveOffers(connectOfferPage.offers)
  const dedupedOffers = dedupeOffers(activeOffers)

  useEffect(() => {
    ThirdPartyTracking.sessionReplay('?page=multi_offer')
  }, [])

  const renderOfferButtons = () =>
    dedupedOffers.map((offer) => {
      if (offer.consumerGroup?.toLowerCase() !== selectedCCG?.toLowerCase()) {
        return null
      }

      return (
        <PageButton
          key={offer.uid}
          title={offer.title}
          description={offer.subtitle}
          onClick={() => addOfferUidToQueryString(offer.uid)}
          brandImage={brand?.logo}
          brandColour={templateConfig?.subtitle?.textColour}
          isOfferButton={true}
        />
      )
    })

  return (
    <>
      <MultiOfferTitle brand={brand} />
      <MultiConnectDescription
        offers={dedupedOffers}
        textColour={templateConfig?.subtitle?.textColour}
        variant={MULTI_OFFER_VARIANT}
      />
      {renderOfferButtons()}
    </>
  )
}

MultiOffer.propTypes = {
  brand: PropTypes.object.isRequired,
  connectOfferPage: PropTypes.shape({
    offers: PropTypes.arrayOf(PropTypes.object).isRequired
  }).isRequired,
  addOfferUidToQueryString: PropTypes.func.isRequired,
  selectedCCG: PropTypes.string.isRequired,
  isExpress: PropTypes.bool.isRequired
}

export default createFragmentContainer(MultiOffer, {
  connectOfferPage: graphql`
    fragment multiOffer_connectOfferPage on ConnectOfferPage {
      offers {
        uid
        consumerGroup
        status
        enabled
        title
        subtitle
      }
    }
  `,
  brand: graphql`
    fragment multiOffer_brand on Brand {
      logo
      ...multiOfferTitle_brand
    }
  `
})
