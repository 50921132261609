import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const hr = css`
  border: 0;
  height: 1px;
  background: ${Colours('grey', 100)};
  margin: 16px 0;
  width: 100%;
`
