import { css } from '@emotion/react'

import Colours from '@thebeansgroup/ui_style_helpers/colours'

export const icon = css`
  margin-top: -4px;
`

export const listItem = css`
  color: ${Colours('grey', 500)};
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2px;
`

export const container = css`
  display: flex;
  flex-direction: column;
  gap: 8px;
`
export const footnote = css`
  margin-bottom: 8px;
`
