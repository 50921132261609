/**
 * @generated SignedSource<<d9f73ae747eb83ca6d0544b9941c3850>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "tileTemplateLayout_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "brandImages_brand"
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

node.hash = "2f985be46f57b3ec415d795d54938a38";

module.exports = node;
