import PropTypes from 'prop-types'
import { createFragmentContainer, graphql } from 'react-relay'

import { withI18n } from '@thebeansgroup/_i18n'
import { Colours } from '@thebeansgroup/ui_style_helpers'
import Footnote from '@thebeansgroup/ui_typography/footnote'
import Headline from '@thebeansgroup/ui_typography/headline'

import VerticalStepper from '@components/vertical_stepper'

import { GRADUATION_FOOTNOTE } from './constants'
import {
  getDescriptionHeading,
  getItemText,
  getTimelineItems,
  shouldDisplayGraduateSubtitle
} from './helpers'
import * as styles from './styles'

const TileTemplateDescription = ({ t, offer }) => {
  const renderGraduateSubtitle = (index) => {
    if (!shouldDisplayGraduateSubtitle(offer.consumerGroup, index)) return null

    return (
      <div css={styles.footnote}>
        <Footnote colour={Colours('grey', 400)}>
          {t('m_enjoy_5_years_after_graduating') || GRADUATION_FOOTNOTE}
        </Footnote>
      </div>
    )
  }

  return (
    <div css={styles.container}>
      <Headline
        component='h3'
        fontWeight='semibold'
        colour={Colours('grey', 500)}
      >
        {getDescriptionHeading({ t, offer })}
      </Headline>

      <VerticalStepper
        steps={getTimelineItems(offer).map((item, index) => (
          <div
            key={index}
            css={styles.listItem}
          >
            <span key={index}>{getItemText({ t, item })}</span>

            {renderGraduateSubtitle(index)}
          </div>
        ))}
      />
    </div>
  )
}

TileTemplateDescription.propTypes = {
  t: PropTypes.func.isRequired,
  offer: PropTypes.shape({
    redemptionType: PropTypes.string,
    consumerGroup: PropTypes.string
  }).isRequired
}

export default createFragmentContainer(withI18n(TileTemplateDescription), {
  offer: graphql`
    fragment tileTemplateDescription_offer on Offer {
      redemptionType
      consumerGroup
    }
  `
})
