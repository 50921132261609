/**
 * @generated SignedSource<<e8921c57bb3b871955101a560bfd461c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "tileTemplateOffer_brand",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "tileTemplateTitle_brand"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "tileTemplateLayout_brand"
    }
  ],
  "type": "Brand",
  "abstractKey": null
};

node.hash = "51d64fd764dc5a60d45c4e6cf322e5bc";

module.exports = node;
